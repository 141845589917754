<template>
	<v-container>
		<v-row>
			<v-col v-if="isLoading" cols="12" sm="10" md="8" class="ml-auto mr-auto">
				<v-card class="pt-3" elevation="6">
					<v-skeleton-loader type="list-item-avatar,list-item-two-line"></v-skeleton-loader>
				</v-card>
			</v-col>
			<v-col v-else cols="12" sm="10" md="8" class="ml-auto mr-auto pl-sm-0">
				<v-card flat v-for="testimonial in allTestimonials" :key="testimonial.id">
					<v-list-item class="pl-sm-0">
						<v-list-item-avatar color="grey"></v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="headline">{{testimonial.name}}</v-list-item-title>
							<v-list-item-subtitle>{{testimonial.dateAdded.toLocaleString()}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-card-text class="pl-sm-0">{{testimonial.comment}}</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="text-center">
				<v-btn color="#8c9eff" text @click="openAddForm">
					<v-icon>mdi-plus</v-icon>Add your testimonial
				</v-btn>
				<AddTestimonial v-model="isOpen" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddTestimonial from "../components/Testimonials/AddTestimonial";
export default {
	data: () => {
		return {
			isLoading: true,
			isOpen: false,
		};
	},
	computed: mapGetters(["allTestimonials"]),
	methods: {
		...mapActions(["fetchTestimonials"]),
		openAddForm() {
			this.$data.isOpen = true;
		},
	},
	mounted() {
		this.fetchTestimonials().then(() => {
			this.$data.isLoading = false;
		});
	},
	components: {
		AddTestimonial,
	},
};
</script>