<template>
	<div>
		<v-card-text>
			<v-form v-model="isFormValid" @submit="submitForm">
				<v-text-field
					:disabled="value"
					label="Your Name"
					v-model="name"
					placeholder="Please provide your name"
					:rules="nameRule"
					required
				></v-text-field>
				<v-text-field
					:disabled="value"
					v-model="email"
					placeholder="Please provide your email"
					:rules="emailRule"
					label="Your Email"
					required
				></v-text-field>
				<label>Rate me out of 5</label>
				<v-rating
					:size="32"
					:color="'yellow darken-1'"
					:hover="true"
					:emptyIcon="'mdi-star-outline'"
					:fullIcon="'mdi-star'"
					v-model="rating"
					class="mb-3"
				></v-rating>
				<v-textarea
					:disabled="value"
					auto-grow
					v-model="comment"
					:rules="commentRule"
					label="Comment"
					placeholder="Please explain in detail"
					rows="2"
				></v-textarea>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="green darken-1" text @click="submitForm">Submit</v-btn>
			<v-btn color="red lighten-1" text @click="close">Cancel</v-btn>
		</v-card-actions>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	props: {
		value: {},
	},
	data: () => {
		return {
			name: "",
			nameRule: [(v) => !!v || "Name is required"],
			email: "",
			emailRule: [
				(v) => !!v || "E-mail is required",
				(v) => /.+@.+/.test(v) || "E-mail must be valid",
			],
			rating: 5,
			comment: "",
			commentRule: [(v) => !!v || "Comment is required"],
			isFormValid: false,
			isFormSubmitted: false,
		};
	},
	methods: {
		...mapActions(["addTestimonial"]),
		close() {
			this.$emit("close");
		},
		submitForm(event) {
			event.preventDefault();
			if (this.$data.isFormValid) {
				this.$emit("input", true);
				this.addTestimonial({
					name: this.$data.name,
					email: this.$data.email,
					comment: this.$data.comment,
					rating: this.$data.rating,
				}).then(() => {
					this.$emit("input", false);
					this.$data.isFormSubmitted = true;
				});
			}
		},
	},
};
</script>