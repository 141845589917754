<template>
	<v-dialog persistent :fullscreen="isFullScreen" v-model="dialog" max-width="400">
		<v-card :loading="isFormSubmitting">
			<v-toolbar v-if="isFullScreen" dark color="#8c9eff">
				<v-btn icon dark @click="dialog = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>Add your testimonial</v-toolbar-title>
			</v-toolbar>
			<v-card-title class="headline">Add your testimonial</v-card-title>
			<TestimonialForm v-model="isFormSubmitting" @close="dialog = false" />
		</v-card>
	</v-dialog>
</template>

<script>
import TestimonialForm from "./TestimonialForm";
export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	data: () => {
		return {
			isFormSubmitting: false,
		};
	},
	computed: {
		isFullScreen() {
			return this.$vuetify.breakpoint.name === "xs";
		},
		dialog: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	components: {
		TestimonialForm,
	},
};
</script>